{
  "message": "Bienvenue sur KPI-App !",
  "status": {
    "Offline": "Vous n'êtes pas connecté !"
  },
  "nav": {
    "Home": "Accueil",
    "Login": "Connexion",
    "MyAccount": "Mon compte",
    "Staff": "Organisation",
    "Games": "Matchs",
    "Ranking": "Classement",
    "Chart": "Progression",
    "GameReport": "Feuille de marque",
    "Stats": "Stats",
    "StatReport": "Stats du match",
    "Scrutineering": "Vérifications",
    "ScrutReport": "Vérifications techniques des équipements",
    "Lang": "Langue",
    "Back": "Retour",
    "CommingSoon": "Prochainement...",
    "CommingSoonDescription": "Soyez patient !",
    "About": "A propos",
    "Filters": "Filtres",
    "ChangeEvent": "Changez d'événement SVP",
    "StaffJobs": "Missions organisation"
  },
  "AddToHomeScreen": {
    "message": "Ajouter au bureau / à l'écran d'accueil.",
    "Dismiss": "Refuser",
    "Install": "Installer"
  },
  "Login": {
    "Authentication": "Authentification",
    "Login": "Identifiant",
    "LoginHelp": "Entrez votre numéro de licence",
    "Password": "Mot de passe",
    "Submit": "Connexion",
    "EmptyMsg": "Identifiant et mot de passe sont requis !",
    "UnauthorizedMsg": "Connexion refusée !",
    "ErrorMsg": "Erreur durant l'authentification !",
    "Welcome": "Bienvenue",
    "Logout": "Déconnexion"
  },
  "Event": {
    "SelectEvent": "Sélectionnez un événement",
    "PleaseSelectOne": "Sélectionnez un événement",
    "Event": "Événement",
    "Change": "Changer",
    "Confirm": "Confirmer",
    "Cancel": "Annuler",
    "StdEvents": "Événements",
    "LocalChamp": "Championnats locaux"
  },
  "Teams": {
    "SelectTeam": "Sélectionner une équipe",
    "PleaseSelectOne": "Sélectionner une équipe",
    "Team": "Equipe",
    "Change": "Changer",
    "Confirm": "Confirmer",
    "Cancel": "Annuler"
  },
  "Games": {
    "Categories": "Catégories",
    "Teams": "Equipes",
    "Refs": "Arbitres",
    "ShowRefs": "Voir les arbitres",
    "ShowFlags": "Voir les logos",
    "Dates": "Dates",
    "Time": "Heure",
    "All": "Toutes",
    "AllDates": "Toutes dates",
    "Today": "Aujourd'hui",
    "Tomorow": "Demain",
    "Prev": "Précédents matchs",
    "Next": "Prochains matchs",
    "games": "matchs",
    "Game": "Match",
    "Pitch": "Terr.",
    "Date": "Date",
    "Cat": "Cat.",
    "Group": "Poule",
    "Team": "Equipe",
    "Score": "Score",
    "Ref": "Arb.",
    "Referee": "Arbitrage",
    "GameReport": "Feuille de marque",
    "Status": {
      "ATT": "En attente",
      "ON": "En cours",
      "END": "Terminé"
    },
    "Period": {
      "M1": "1ère période",
      "M2": "2nde période",
      "P1": "1ère prolong.",
      "P2": "Prolongation",
      "TB": "Tirs au but"
    },
    "Code": {
      "Group": "Groupe ",
      "Team": "Equipe ",
      "Winner": "Vainqueur #",
      "Looser": "Perdant #"
    },
    "Numbers": {
      "first": "er ",
      "second": "nd ",
      "third": "e ",
      "eleven": "e ",
      "twelve": "e ",
      "thirteen": "e ",
      "twentyone": "e ",
      "twentytwo": "e ",
      "twentythree": "e ",
      "th": "e "
    }
  },
  "Charts": {
    "Ranking": "#",
    "Team": "Equipe",
    "Pts": "Pts",
    "Pld": "J",
    "W": "G",
    "D": "N",
    "L": "P",
    "F": "F",
    "GF": "+",
    "GA": "-",
    "GD": "+/-",
    "FinalRanking": "Classement final",
    "ProvisionalRanking": "Classement provisoire"
  },
  "About": {
    "DoYouLike": "Cette application vous plait ?",
    "IDevelopIt": "Je la développe sur mon temps libre.",
    "Rating": "Evaluez cette application :",
    "Average": "Note moyenne",
    "voters": "votants",
    "FeedbackOnTwitter": "Donnez vos retours sur",
    "SupportMeOnUtip": "Soutenez-moi sur",
    "OwnCompetition": "Vous aimeriez utiliser cette application pour votre propre compétition, ou dans une autre langue ?",
    "HelpMe": "Vous maitrisez le javascript, le php, le webdesign et souhaitez m'aider ?",
    "ContactMe": "Contactez-moi :"
  },
  "Rating": {
    "AboutThisApp": "A propos de cette application...",
    "Thanks": "Merci !"
  },
  "Update": {
    "Message": "Une mise à jour est disponible",
    "Button": "Appliquer maintenant !"
  },
  "Scrutineering": {
    "ChangeEvent": "Vous n'avez pas les droits suffisants pour cet événement ! ",
    "Scrutineering": "Vérifications équipement",
    "Player": "Joueur",
    "Helmet": "Casque",
    "Vest": "Gilet",
    "Kayak": "Kayak",
    "Paddles": "Pagaies",
    "Coach": "Coach",
    "PrintAll": "Imprimer tout",
    "Cosmetic": "Cosmétique",
    "Safety": "Sécurité",
    "Technical": "Techn.",
    "Issues": "Problèmes"
  }
}